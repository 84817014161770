body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.container {
  margin-left: 100px;
  /* Slight misalignment */
  margin-top: 50px;
}

h1 {
  font-size: 24px;
  color: #333;
  /* Replace with your primary heading color */
  margin-bottom: 40px;
}

h2 {
  font-size: 20px;
  color: #333;
  /* Replace with your secondary heading color */
  margin-top: 30px;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
  color: #333;
  /* Replace with your secondary heading color */
  margin-top: 10px;
  margin-bottom: 10px;
}

ul {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
  line-height: 1.5;
}

p {
  margin: 10px 0;
  line-height: 1.6;
}

a {
  color: #2a5820;
}

/* Variables */
:root {
  --color-primary: #4CAF50;
  --color-primary-hover: #45a049;
  --color-text: #4a4a4a;
  --color-text-secondary: #666;
  --color-accent: #ffd43b;
  --color-accent-hover: #fcc419;
  --color-disabled: #cccccc;
  --color-white: #fff;

  --font-family-hand: 'Patrick Hand', 'Comic Neue', 'Comic Sans MS', cursive;
  --font-family-mono: 'JetBrains Mono', monospace;

  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 2px 8px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 4px 12px rgba(0, 0, 0, 0.12);

  --transition-quick: 0.2s ease;
  --transition-standard: 0.3s ease;
}